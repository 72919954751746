import { useCurrentOrganizationId } from '~/src/shared/lib/current-user';
import type { OrganizationCreate, OrganizationRead, OrganizationUpdate, UserOrganizations } from './model';
import { type ApiPaths, type JsonRequestBody, type JsonResponse, apiFetch, getApiFetchInstance, useApiAccessToken } from '~/src/shared/api';

interface MembershipAccept {
  token: string;
  password?: string;
}

export type OrganizationReadByMember = JsonResponse<ApiPaths['/core/v1']['/organizations/my']['get'], 200>[number];

export function useOrganizationsRepo() {
  const accessToken = useApiAccessToken();
  const authenticatedFetch = getApiFetchInstance(accessToken);

  return {
    /**
     * Get the organizations that the user is a member of or owns.
     * @returns The organizations that the user is a member of or owns.
     */
    async getMy() {
      return authenticatedFetch<JsonResponse<ApiPaths['/core/v1']['/organizations/my']['get'], 200>>('/core/v1/organizations/my');
    },

    async create(organization: JsonRequestBody<ApiPaths['/core/v1']['/organizations/create']['post']>) {
      return authenticatedFetch<JsonResponse<ApiPaths['/core/v1']['/organizations/create']['post'], 200>>('/core/v1/organizations/create', {
        method: 'POST',
        body: organization,
      });
    },
  };
}

export async function apiGetMyOrganizations() {
  return apiFetch<UserOrganizations>('/v1/organizations/my');
}

export async function acceptMembershipInvitation(accept: MembershipAccept) {
  return apiFetch<null>('/v1/organizations/accept-invitation', {
    method: 'POST',
    body: accept,
  });
}

export async function apiUpdateOrganization(organization_id: string, organization: OrganizationUpdate) {
  return apiFetch<OrganizationRead>(`/v1/organizations/${organization_id}`, {
    method: 'PATCH',
    body: organization,
  });
}
