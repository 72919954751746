import { apiGetMyOrganizations, apiUpdateOrganization } from './api';

interface OrganizationSubscription {
  tier_type: string;
  status: string;
}

export interface OrganizationUpdate {
  name?: string;
  handle?: string;
  timezone_id?: string;
  currency_code?: string;
  contact_email?: string;
  offerings_mode?: boolean;
  country_code_iso3166?: string;
}

export interface OrganizationCreate extends OrganizationUpdate {
  name: string;
  country_code_iso3166: string;
}

export interface OrganizationReadForMember {
  id: string;
  name: string;
  handle: string;
  offering_mode: boolean;
  organization_subscription: OrganizationSubscription;
}

export interface OrganizationRead extends OrganizationReadForMember {
  country_code_iso3166: string;
  timezone_id: string;
  currency_code: string;
  contact_email: string;
  created_at: string;
  updated_at: string;
  owner_id: string;
  stripe_customer_id: string;
}

export interface UserOrganizations {
  owned: OrganizationRead[];
  membership: OrganizationReadForMember[];
}

export function getInitialOrganizationValues(): OrganizationCreate {
  return {
    name: '',
    handle: '',
    country_code_iso3166: 'US',
  };
}

export const useOrganizationStore = defineStore('organization', () => {
  const organization = ref<OrganizationRead | null>(null);
  const ownedOrganizations = ref<OrganizationRead[]>([]);
  const membershipOrganizations = ref<OrganizationReadForMember[]>([]);

  /**
   * Initializes the organization store if it hasn't been initialized yet. Otherwise, does nothing.
   * If an organization ID is provided, it will be set as the current organization.
   *
   * @param organizationID ID of organization to set as current organization
   */
  async function initialize(organizationID?: string) {
    // if (!ownedOrganizations.value.length && !membershipOrganizations.value.length) {
    const myOrganizations = await apiGetMyOrganizations();
    ownedOrganizations.value = myOrganizations.owned ?? [];
    membershipOrganizations.value = myOrganizations.membership ?? [];
    // }
    // if (!organization.value) {
    if (organizationID) {
      organization.value = ownedOrganizations.value.find(org => org.id === organizationID) ?? ownedOrganizations.value[0] ?? null;
    } else {
      organization.value = ownedOrganizations.value[0] ?? null;
    }
    // }
  }

  async function updateOrganizationSettings(data: OrganizationUpdate) {
    if (!organization.value) {
      return;
    }
    const updatedOrg = await apiUpdateOrganization(organization.value.id, data);
    const index = ownedOrganizations.value.findIndex(org => org.id === updatedOrg.id);
    if (index !== -1) {
      ownedOrganizations.value[index] = updatedOrg;
    }
    organization.value = updatedOrg;
  }

  return {
    ownedOrganizations,
    membershipOrganizations,
    organization,
    initialize,
    updateOrganizationSettings,
  };
});
